import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import { SocialGrid, Map } from "../components/common"

const FullStackDeveloperPage = () => (
  <Layout>
    <div
      css={theme => css`
        margin: ${theme.constants.pageMargin};

        @media (max-width: 1200px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 1000px) {
          margin: ${theme.constants.smallpageMargin};
        }

        @media (max-width: 880px) {
          margin: 0;
        }
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          css={css`
            width: 100%;

            @media (max-width: 880px) {
              width: 100%;
              padding: 0 20px;
            }
          `}
        >
          <h1
            css={theme => css`
              font-size: ${theme.constants.largeFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 25px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.mediumFontSize}px;
              }
            `}
          >
            Fullstack developer
          </h1>
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.extraLight};
              margin-bottom: 50px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 50px;
              }
            `}
          >
            We are looking for a fullstack developer with 2+ years of experience
            for startup product development. For the smooth work your technology
            knowledge of Javascript, React and Node.js is required to be stable.
          </p>
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.extraLight};
              margin-bottom: 25px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 25px;
              }
            `}
          >
            Level: Medior
          </p>
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.extraLight};
              margin-bottom: 25px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 25px;
              }
            `}
          >
            Technologies required:
          </p>
          <p
            css={theme => css`
              font-size: ${theme.constants.smallFontSize}px;
              font-weight: ${theme.constants.extraLight};
              line-height: 1.5;

              margin-bottom: 25px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.infoDescriptionTextSize}px;
                margin-bottom: 30px;
              }
            `}
          >
            <ul
              css={css`
                margin: 25px 0px 25px 25px;
              `}
            >
              <li>React</li>
              <li>Javascript</li>
              <li>Node.js</li>
              <li>CSS</li>
              <li>HTML</li>
              <li>Redux</li>
              <li>Git</li>
              <li>Rest</li>
            </ul>
          </p>
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.extraLight};
              margin-bottom: 25px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 50px;
              }
            `}
          >
            If you are interested in working with us and would like to know more
            about Deverest please fill out this{" "}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSekLCplCsxperOsF9xGwoX6Z8bbHCeEh04W3Z8--OPqFmgapQ/viewform">
              survey
            </a>{" "}
            and we will contact you.
          </p>
        </div>
      </div>
    </div>
    <Map />
    <SocialGrid />
  </Layout>
)

export default FullStackDeveloperPage
